import React, { Component } from "react";
import CalendarPicker from "./CalendarPicker";
import TimeSlots from "./TimeSlots";
import moment from "moment";
import PropTypes from "prop-types";
import { A4P_VET_DOC_APPOINTMENT_SLOT_DURATION } from "data/config/variables";

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDt: moment(),
      selectedTimeSlots: this.props.selectedTimeSlots
    };
    this.onChange = this.onChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onTimeSlotSelected = this.onTimeSlotSelected.bind(this);
    this.setDefaultWeekHours = this.setDefaultWeekHours.bind(this);
    this.copyToNWeeks = this.copyToNWeeks.bind(this);
  }

  componentDidMount() {
    if (this.props.setDefaultWorkingHours) this.setDefaultWeekHours();
  }

  // function to set default working hours of the current week
  setDefaultWeekHours() {
    // set constants
    const morngStartTime = 8 * 60 + 30; // 8.30 am in minutes
    const morngEndTime = 12 * 60; // 12pm
    const eveStartTime = 14 * 60; // 2pm
    const eveEndTime = 18 * 60; //6pm

    // valid slots to be pushed into this array
    let selAry = [];

    // get start of week
    let currWkDay = moment().startOf("week");

    // loop for next 5 working days
    [...Array(5)].forEach((_, i) => {
      currWkDay.add(1, "d");
      let dt = moment(currWkDay);
      while (dt.isSame(currWkDay, "day")) {
        // add slot
        let s = moment(dt.add(A4P_VET_DOC_APPOINTMENT_SLOT_DURATION, "m"));
        let tm = s.hours() * 60 + s.minutes();
        // check if this s is in Active Hours
        if (
          (tm >= morngStartTime && tm < morngEndTime) ||
          (tm >= eveStartTime && tm < eveEndTime)
        )
          selAry.push(s.valueOf());
      }
    });

    // set the selected slots and upon update copy the same to foll weeks
    this.setState({ selectedTimeSlots: selAry }, () => {
      if (this.props.copyDefaultWorkingHourstoWeeks)
        this.copyToNWeeks(this.props.copyDefaultWorkingHourstoWeeks);
    });
  }

  onDateChange(e) {
    this.setState({ currentDt: e });
  }

  onTimeSlotSelected(ts) { 
    if(this.props.mode === 'schedule') this.setState({ selectedTimeSlots: ts });
    if (this.props.onTimeSlotSelected) this.props.onTimeSlotSelected(ts);
  }

  onChange(e) {
    this.copyToNWeeks(e.target.value);
  }

  // function to copy the current weeks shcedule to Next N weeks
  copyToNWeeks(n) {
    let wks = parseInt(n);
    if (wks < 1) return;

    const startOfWeek = moment(this.state.currentDt).startOf("week");
    const endOfWeek = moment(this.state.currentDt).endOf("week");

    // get all ts upto this week
    let uptoThisWeek = this.state.selectedTimeSlots.filter(item =>
      moment(item).isSameOrBefore(endOfWeek)
    );

    // get this week
    let thisWeek = uptoThisWeek.filter(item =>
      moment(item).isSameOrAfter(startOfWeek)
    );

    // for each  add more
    for (let i = 1; i <= wks; i++) {
      thisWeek.forEach(item => {
        uptoThisWeek.push(
          moment(item)
            .add(i, "w")
            .valueOf()
        );
      });
    }

    this.onTimeSlotSelected([...uptoThisWeek]);
    alert(`Note: This week's schedule has been copied to next ${wks} weeks!`);
  }

  render() {
    return (
      <div>
        <CalendarPicker
          onDateChange={this.onDateChange}
          currentDt={this.state.currentDt}
        />

        <TimeSlots 
          onTimeSlotSelected={this.onTimeSlotSelected}
          selectedTimeSlots={this.state.selectedTimeSlots}
          currentDt={this.state.currentDt} 
          mode={this.props.mode}
        />

        {this.props.showCopyToWeeks && (
          <div className="form-group text-right">
            <label htmlFor="select1">
              Copy this week's schedule for foll. weeks
            </label>
            <select
              value={this.state.value}
              onChange={this.onChange.bind(this)}
              className="ml-2"
            >
              <option>Select</option>
              <option value="1">Next 1 Week</option>
              <option value="2">Next 2 Weeks</option>
              <option value="3">Next 3 Weeks</option>
              <option value="4">Next 4 Weeks</option>
              <option value="5">Next 5 Weeks</option>
              <option value="6">Next 6 Weeks</option>
              <option value="7">Next 7 Weeks</option>
            </select>
          </div>
        )}
      </div>
    );
  }
}

Schedule.defaultProps = {
  mode: "schedule", // schedule - set schedule; pick - pick a ts
  showCopyToWeeks: true,
  setDefaultWorkingHours: false,
  copyDefaultWorkingHourstoWeeks: 0,
  selectedTimeSlots: []
};

Schedule.propTypes = {
  onTimeSlotSelected: PropTypes.func,
  selectedTimeSlots: PropTypes.array,
  copyDefaultWorkingHourstoWeeks: PropTypes.number,
  setDefaultWorkingHours: PropTypes.bool,
  showCopyToWeeks: PropTypes.bool,
  mode: PropTypes.string,
};

export default Schedule;
