import React, { Component } from 'react';
import moment from "moment";

export default class PromoPlan extends Component {
    render() {
        let { code, description, price } = this.props.data;
    return (
      <div>
        <p>PromoPlan Payment:</p>
        <div className="summary">
          <ul className="list-unstyled">
            <li>
              Code:
              <strong className="float-right">{code}</strong>
            </li>
          </ul>
        </div>
        <ul className="checkout clearfix list-unstyled">
          <li>
            Description
            <small className="d-block">{description}</small>
          </li>
          <li className="total font-weight-bold">
            Total <strong className="price">${price}</strong>
          </li>
        </ul>
      </div>
    );
    }
}
