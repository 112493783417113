import React, { Component } from "react";

export default class ourPartners extends Component {
    render() {
        return (
            <main>
                <div className="position-relative my-5 pageTitle">
                    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
                    <div className="position-absolute w-100 text-center text-white page-tagline">
                        <h1 className="w-70 m-auto pt-5 pb-5">Our Partners</h1>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="border-L rounded p-4">
                        <p>
                            <strong>Humane Society of Broward County</strong>
                        </p>
                        <p>
                            The Humane Society of Broward County provides shelter, aid and responsible adoptions to
                            animals entrusted to their care, and educates the community about respect and kindness to
                            all animals.
                        </p>
                        <p>
                            Identified nationally as a “Leader in Animal Welfare,” the Humane Society of Broward County
                            is a 33,000 square-foot, completely air-conditioned facility with the ability to house over
                            300 dogs and cats. The Humane Society of Broward County is an open admission shelter with
                            dedicated veterinarian services for their animals.
                        </p>
                        <a href="#!">www.humanebroward.com</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>North Shore Animal League America</strong>
                        </p>
                        <p>
                            A national and international leader in the no-kill movement, North Shore Animal League
                            America is committed to saving and rehabilitating the lives of homeless companion animals
                            and educating the public about the joys and responsibilities of rescue. Founded in 1944,
                            they are the largest, most comprehensive no-kill rescue and adoption organization in the
                            world, with more than 2,000 shelter partners across the country and around the globe.
                            Headquartered in Port Washington, N.Y., they rescue, nurture, and adopt nearly 18,000 pets
                            annually into responsible, loving homes, plus assist in the adoptions of thousands more
                            through our global partnerships.
                        </p>
                        <p>
                            Over the years, they’ve introduced such lifesaving best practices as mobile adoption,
                            offsite adoption, puppy mill rescue, national low-cost spay/neuter referral, humane
                            relocation, and most recently, the Mutt-i-grees® Curriculum, a transformative humane
                            education program based on social-emotional learning and the natural affinity between
                            children and animals. Introduced in 2010, the Mutt-i-grees Curriculum is strengthening the
                            human-animal bond for more than 3,000,000 students across North America, and creating future
                            generations of informed and empathetic adopters.
                        </p>
                        <a href="#!">www.animalleague.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>Peggy Adams Animal Rescue League</strong>
                        </p>
                        <p>
                            Peggy Adams Animal Rescue League provides services to over 28,000 cats, dogs, puppies and
                            kittens each year. Operating continuously since 1925, we have been positively impacting
                            animal welfare and addressing overpopulation in Palm Beach County through a variety of
                            services.
                        </p>
                        <p>
                            Our Mission is to provide shelter to lost, homeless and unwanted animals, to provide spay
                            and neuter and other medical services for companion animals, and to care for, protect, and
                            find quality homes for homeless and neglected companion animals, to advocate animal welfare,
                            community involvement and education to further the bond between people and animals.
                        </p>
                        <a href="#!">www.peggyadams.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>Larimer Humane Society</strong>
                        </p>
                        <p>
                            Founded in 1969, Larimer Humane Society is an independent, non-profit organization whose
                            mission is to promote and provide the responsible care and treatment of animals.
                        </p>
                        <p>
                            As Northern Colorado’s largest open-admission animal care facility, we provide shelter,
                            medical attention and care to thousands of lost, abandoned, injured, abused, ill and
                            orphaned animals each year.
                        </p>
                        <a href="#!">www.larimerhumane.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>Dogs on Deployment</strong>
                        </p>
                        <p>
                            Military members nation-wide confront a multitude of problems during their careers, the last
                            thing they need to worry about is their pet’s care during their service commitments. Dogs On
                            Deployment is a 501(c)(3) national non-profit which provides an online network for service
                            members to search for volunteers who are willing to board their pets during their owner’s
                            service commitments. Dogs on Deployment promotes responsible, life-long pet ownership by
                            military pet owners by advocating for military pet owner rights, providing educational
                            resources and granting financial assistance for military pet owners during times of
                            emergency.
                        </p>
                        <a href="#!">www.dogsondeployment.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>Humane Society Tacoma &amp; Pierce County</strong>
                        </p>
                        <p>
                            Established in 1888, the Humane Society for Tacoma &amp; Pierce County focuses largely on
                            protecting dogs and cats, solving the burgeoning pet overpopulation problem, and developing
                            innovative humane programs to serve the entire community. The Humane Society shelters more
                            than 12,000 animals every year, maintains more than a dozen humane programs, and is at the
                            forefront of the progressive animal welfare.
                        </p>
                        <a href="#!">www.thehumanesociety.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>Atlanta Humane Society</strong>
                        </p>
                        <p>
                            Atlanta Humane Society (AHS) is a 501(c)3 tax exempt nonprofit (IRS Tax ID# 58-0685900) and
                            one of the oldest private charitable organizations in Atlanta. AHS was founded in 1873 and
                            was originally chartered after the Civil War to protect women, children and animals. AHS is
                            a nationally-recognized leader in animal welfare and positively impacts over 25,000 animals
                            a year through companion pet adoptions, preventative and veterinary services, behavior
                            training and educational programs. AHS maintains two adoption centers and campuses in the
                            Atlanta Metro Area with the first established in West Midtown in the 1940’s and a new campus
                            in Alpharetta that was opened in 2011. AHS is committed to finding homes for all adoptable
                            companion animals and placed 8,300 dogs and cats into permanent, loving homes in 2015. AHS
                            is proud to be a part of the solution to ending pet homelessness and overpopulation in the
                            southeastern United States.
                        </p>
                        <a href="#!">www.atlantahumane.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>spcaLA</strong>
                        </p>
                        <p>
                            Society for the Prevention of Cruelty to Animals Los Angeles (spcaLA) is an independent,
                            nonprofit animal welfare organization serving Southern California since 1877. Donations run
                            programs and services including Cruelty Investigations, Disaster Animal Response Team,
                            Humane Education and a variety of shelter services. spcaLA believe that animals, as living
                            creatures, have value beyond economic measurement, and are entitled to legal, moral and
                            ethical consideration and protection.
                        </p>
                        <a href="#!">www.spcala.com</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>SPCA of Texas</strong>
                        </p>
                        <p>
                            The SPCA of Texas is the leading animal welfare organization in North Texas. Founded in
                            1938, the non-profit operates two shelters, three spay/neuter clinics and an emergency
                            animal rescue center, all located in Dallas and Collin Counties, and maintains a team of
                            animal cruelty investigators who respond to thousands of calls in eight North Texas
                            counties. Moreover, the SPCA of Texas serves as an active resource center for an array of
                            services that bring people and animals together to enrich each other’s lives. The SPCA of
                            Texas is dedicated to providing every animal exceptional care and a loving home.
                        </p>
                        <a href="#!">www.spca.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>All Breeds Rescue, Vermont</strong>
                        </p>
                        <p>
                            All Breed Rescue is a compassionate, non-profit rescue dedicated to saving highly adoptable
                            dogs from high kill shelters and placing them in loving homes in the Northeast.
                        </p>
                        <p>
                            Established in 1996; All Breed Rescue has rescued over 6,000 dogs from high kill shelters
                            and has grown a vast network of volunteers and fosters in the process. As a compassionate
                            non-profit dog rescue, we work within the community through local schools, colleges and
                            businesses to raise awareness of overpopulation in our country.
                        </p>
                        <p>
                            As we aim for the future, we look to our community to aid us in saving dogs who are homeless
                            of any breed, size and age through basic training, socializing, networking fostering,
                            adopting and sponsoring. It’s with these actions we are able to place these abandoned dogs
                            in caring fosters and loving homes here in the generous Northeast.
                        </p>
                        <a href="#!">www.allbreedrescuevt.com</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>The Cat Network, Inc.</strong>
                        </p>
                        <p>
                            The Cat Network, Inc. is a grass-roots organization with over 2,500 volunteer and donor
                            members. Its members have trapped and sterilized over 51,000 stray and abandoned cats and
                            have adopted out over 7,000 cats since its inception in December of 1995. Its mission is to
                            make everyone aware of this huge community problem and to be proactive in fighting it by
                            spaying and neutering pets and strays, alike.
                        </p>
                        <p>
                            The Cat Network encourages the public to adopt stray and abandoned cats as indoor-only pets
                            and practice responsible ownership. They also mediate with planned communities and
                            multi-family developments to enact TNR (trap/neuter/release) efforts rather than
                            extermination of stray cats on their properties. Through combined efforts with other local
                            animal agencies they are working to make South Florida a No-Kill community. They also
                            cooperate with and assist other local governmental agencies in animal hoarder situations.
                        </p>
                        <a href="#!">www.thecatnetwork.org</a>
                    </div>
                    <div className="border-L rounded p-4 mt-5">
                        <p>
                            <strong>Capital Humane Society</strong>
                        </p>
                        <p>
                            Capital Humane Society serves the community by sheltering unwanted pets, acts as an advocate
                            for animal welfare and strives to educate the public about responsible pet care.
                        </p>
                        <a href="#!">www.capitalhumanesociety.org</a>
                    </div>
                </div>
            </main>
        );
    }
}

 