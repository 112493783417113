import React, { Component } from "react";

export default class SMO extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.props.history.push("/second-medical-opinion/order");
  }

  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">Second Medical Opinion</h1>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col-sm-6">
              <h3>Do I have other options? </h3>
              <h5 className="mt-4 text-black-50" style={{ lineHeight: "28px" }}>
                Not every doctor will have the same opinion when it comes to
                diagnosis and treatments options. A doctor’s opinion is guided
                by several factors such as available technology, their education
                and their own experience dealing with a particular health
                condition.
              </h5>
              <a
                href="#!"
                className="btn btn-primary rounded-pill btn-lg px-4 mt-4"
                onClick={this.onClick}
              >
                Request a Second Opinion
              </a>
            </div>
            <div className="col-sm-6">
              <img
                src="/img/bookAppointment.jpg"
                className="img-fluid"
                alt="veterinarian online appointment booking"
              />
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <section className="bg-light py-5">
          <div className="container text-center my-5">
            <h2 className="mb-5 pb-4">The Way It Works Is Really Simple</h2>
            <div className="row mt-5">
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">1</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Submit your original diagnosis and supporting documents
                  </h5>
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">2</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Specify your list of questions
                  </h5>
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">3</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Our Team of Specialists will review
                  </h5>
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">4</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Hear back from us within 24~48 hrs
                  </h5>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
          <div className="text-center mt-5">
            <a
              href="#!"
              className="btn btn-primary btn-lg full-rounded mt-4 text-uppercase px-5"
              onClick={this.onClick}
            >
              Raise a Request NOW
            </a>
          </div>
        </section>
        <div className="container bg-white">
          <div
            className="col my-5"
            style={{ lineHeight: "2.2em", color: "#666" }}
          >
            <div className="maininfo">
              
              <p>
                Many patients have been in the difficult position of querying
                their doctor’s medical opinion. And in many cases, it is only
                natural to have medical questions like:
              </p>
              <ul>
                <li>Is my diagnosis correct?</li>
                <li>Is my treatment plan correct?</li>
                <li>
                  Do I <em>need</em> this surgery or do I have other options?
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                At these times it is valuable to have access to an affordable,
                unbiased second opinion so that patients can explore alternative
                options and gain complete confidence in their healthcare.
              </p>
              <h3>How Does Second Medical Opinion Work?</h3>
              <p>
                With the Second Opinion service from Activ Doctors Online,
                members have the opportunity to request medical questions via
                the Internet, at any time and from anywhere in the world. Second
                opinion consults enable patients to make better-informed
                decisions about their health. They also provide a smart and
                inexpensive way to improve health and treatment outcomes.
              </p>
              <p>
                ADO provides its members with access to our network of over
                1,700 world-class healthcare specialists. Our physicians are
                available for medical questions, consultations and second
                opinions within 48 hours of a member’s request (assuming the
                patient’s Personal Health Record is up-to-date). Our specialists
                perform a thorough, evidence-based review of the member’s health
                history, meaning a second opinion from ADO is completely
                independent and impartial.
              </p>
              <h3>Specialist Credentials</h3>
              <p>
                To get started, simply submit a request for a consult. Our
                Medical Director will review your file and forward it on to a
                committee of specialists that best serve your case. Our experts
                will analyze your medical questions and provide a conclusion
                within 48 hours – either directly to you or, upon request, to
                your doctor. In some cases, the second opinion reached our
                medical specialists are the same as those provided by our
                members’ physician. However, they may also provide contrasting
                opinions, suggest further testing, or recommend visiting a
                different specialist.
              </p>
              <h3>How to Request a Second Opinion</h3>
              <p>
                To get started, simply submit a consult request on the ADO
                platform, stating your original diagnosis and your list of
                medical questions. Our Medical Director will review your request
                and delegate it to a committee of specialists that best serve
                your case; these will be experts in the field of your particular
                condition. Our physicians will analyze your medical questions
                and provide a conclusion within 48 hours.
              </p>
              <p>
                The second opinion is delivered as an easy-to-understand report,
                either directly to you (via ADO’s online platform) or, upon
                request, to your doctor. In some cases, the second opinion
                reached by our medical specialists are the same as those
                initially provided by the member’s physician. However, they may
                also provide contrasting opinions, suggest further testing, or
                recommend visiting a different specialist.
              </p>
              <div className="row-fluid">
                <div className="span12">
                  <h5 className="activh5">
                    Your Medical Information, Wherever You Need It
                  </h5>
                  <p>
                    <iframe
                      src="//www.youtube.com/embed/8X7W4EPrtuE"
                      width="100%"
                      height={350}
                      frameBorder={0}
                      allowFullScreen="allowfullscreen"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pt-2 mb-5">
          <a
            href="#"
            className="btn btn-primary btn-lg full-rounded mt-4 text-uppercase px-5"
            onClick={this.onClick}
          >
            Request a Second Opinion now
          </a>
        </div>
      </main>
    );
  }
}
