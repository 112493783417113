import React, { Component } from 'react'

export default class about extends Component {
    render() {
        return (
            <main> 
  <div className="position-relative my-5 pageTitle">
    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
    <div className="position-absolute w-100 text-center text-white page-tagline">
      <h1 className="w-70 m-auto pt-5 pb-5">About ActivDoctors</h1></div></div>
      
      <div className="container py-5">	
      <div
              className="col my-5"
              style={{ lineHeight: "2.2em", color: "#666" }}
            >
          <div className="maininfo">
  <p>With its medical website, Activ Doctors Online is passionate about empowering people to take control of their health destiny. Our mission is to encourage patients to lead healthier, safer and more productive lives by giving access to innovative medical websites providing health information management tools. ADO offers <a title="Personal Health Records" href="/medical-records/">personal health records</a> , <a title="Second Medical Opinion" href="/second-medical-opinion/">second medical opinions</a> and <a title="E-Consultation" href="/e-consultation/">e-consultations</a> all in one unique integrated medical website.</p>
  <p>Our Personal Health Records help individuals and organizations reduce health-related costs while better managing their healthcare. Meanwhile, our E-Consultations and Second Medical Opinion services provide patients with access to some of the world’s top doctors within 48 hours of submitting an online request. More than 2,000,000 members benefit from our health website services worldwide.</p>
  <p>To ensure the utmost security of our members’ personal health records, we use Advanced Encryption Standard (AES) security protocols. All of our solutions are HIPAA-compliant and interoperable with Electronic Medical Records, which healthcare providers must implement on their medical websites by 2014.</p>
  <p>ADO partners with organizations and institutions that want to offer a progressive wellness benefit to their <a title="employees benefits" href="/membership/employers/why-activ/">employees</a> or members. ACTIV members are <a title="ask a doctor" href="/membership/individuals/why-activ/">individuals</a> who control their own healthcare information.</p>
</div>

          </div>
    </div>
</main>

        )
    }
}
