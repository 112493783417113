import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

class CalendarPicker extends Component {
  constructor(props) {
    super(props);
    const startOfWeek = moment(this.props.currentDt).startOf("week");
    this.state = {
      today: startOfWeek,
      selIdx: this.props.currentDt.diff(startOfWeek, "days")
    };
    this.dt_click = this.dt_click.bind(this);
    this.dt_nav_click = this.dt_nav_click.bind(this);
  }

  dt_nav_click(e, v) {
    e.preventDefault();
    const newDt = v
      ? moment(this.state.today).add(this.props.noOfDays, "days")
      : moment(this.state.today).subtract(this.props.noOfDays, "days");

    this.setState({
      selIdx: 0,
      today: newDt
    });

    this.props.onDateChange(moment(newDt));
  }

  dt_click(e) {
    const day = parseInt(e.target.attributes.dt.value);
    this.setState({
      selIdx: day
    });
    this.props.onDateChange(moment(this.state.today).add(day, "days"));
  }

  render() {
    return (
      <div className="sch-cal-blk d-flex justify-content-center">
        <div className="sch-cal-lbtn">
          <a
            href="#!"
            className="sch-cal-link prev"
            onClick={e => {
              this.dt_nav_click(e, false);
            }}
          >
            <i className="fa fa-chevron-left" />
          </a>
        </div>
        <div className="sch-cal-days">
          <ul className="sch-cal-days-ul">
            {[...Array(this.props.noOfDays)].map((e, i) => (
              <li
                className={
                  this.state.selIdx === i
                    ? "sch-cal-days-ul-li sel"
                    : "sch-cal-days-ul-li"
                }
                key={i}
                dt={i}
                onClick={this.dt_click}
              >
                {moment(this.state.today)
                  .add(i, "days")
                  .format("ddd, DD MMM")}
              </li>
            ))}
          </ul>
        </div>
        <div className="sch-cal-rbtn">
          <a
            href="#!"
            className="sch-cal-link next"
            onClick={e => {
              this.dt_nav_click(e, true);
            }}
          >
            <i className="fa fa-chevron-right" />
          </a>
        </div>
      </div>
    );
  }
}

CalendarPicker.defaultProps = {
  noOfDays: 7,
  currentDt: moment()
};

CalendarPicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  currentDt: PropTypes.object,
  noOfDays: PropTypes.number
};

export default CalendarPicker;
