import React, { Component } from "react";
import { getConsultationName, getConsultationIcon, getUserRole } from "utils";
import moment from "moment-timezone";
import { A4P_ROLE_VENDOR } from "data/config/variables";
import { Fragment } from "react";
import { RenderSMOStatus } from "./index";

export default class SMOListItem extends Component {
    render() {
        const { smo, timezone } = this.props;
        const { id, details, status, created_at, updated_at } = smo;        
        const slot = moment.tz(created_at, timezone);
        const userRole = getUserRole();
        details.user = {first_name: "John", last_name: "doe"}

        return (
            <div className="col-sm-12 mb-3">
                <div
                    className="shadow-sm border"
                    style={{ cursor: "pointer" }}
                    onClick={e => this.props.onClick(smo)}
                >
                    <div className="d-flex">
                        <div className="align-items-center mr-4 border-right">
                            <div
                                className="text-center"
                                style={{ minWidth: "120px" }}
                            >
                                <div className="py-1 bg-secondary text-white">
                                    <strong>{slot.format("dddd")}</strong>
                                </div>
                                <div className="flex-grow border-top">
                                    <div className="h1 text-muted mb-0">
                                        {slot.format("DD")}
                                    </div>
                                    <div className=" text-muted">
                                        {slot.format("MMMM")}
                                    </div>
                                    <div
                                        className=" text-muted pb-2"
                                        style={{ fontWeight: "900" }}
                                    >
                                        {slot.format("hh:mm A")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-2 flex-grow align-self-center">
                            <div className="mb-2 text-muted">
                                <i
                                    className={`fa mr-2 fa-file`}
                                    style={{ fontSize: "1.25em" }}
                                ></i>
                                Second Medical Opinion
                            </div>
                            {userRole === A4P_ROLE_VENDOR ? (
                                <Fragment>
                                    <div className="mb-1 h4 ">
                                        <strong>{`${details.user.first_name} ${details.user.last_name}`}</strong>
                                    </div>
                                    <div className="mb-1 text-muted lead">
                                        {details.details.title}
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className="mb-1 h4 ">
                                        <strong>ActivDoctors Health Center</strong>
                                    </div>
                                    <div className="mb-1 text-muted lead">
                                        <strong>{`${details.user.first_name} ${details.user.last_name}`}</strong>
                                    </div>
                                </Fragment>
                            )}
                            <div className="d-none d-md-block text-muted">
                                {details.query}
                            </div>
                            {/* show only in mobile device */}
                            <div className="mt-2 lead d-block d-md-none">
                                {RenderSMOStatus(slot, timezone, status)}
                            </div>
                        </div>

                        {/* Hide in mobile devices */}
                        <div className="p-2 ml-auto d-none d-md-flex flex-column justify-content-end text-right">
                            <div className="mb-auto lead">
                                {RenderSMOStatus(slot, timezone, status)}
                            </div>
                            <div className="mb-2 text-dark">#{id}</div>
                            {/* <div className="mb-2 text-dark">
                                {moment
                                    .tz(updated_at, timezone)
                                    .format("DD-MM-YYYY")}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
