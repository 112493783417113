import React, { Component } from "react";
import { Redirect } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import FormElement from "pages/components/form/FormElement";
import {
	A4P_SERVICE_HOME_VISITS,
	A4P_VENDOR_ID,
	A4P_SERVICE_FEES,
	A4P_ROLE_USER,
	A4P_PAYMENT_TYPES
} from "data/config/variables";
import DoctorSchedule from "pages/components/Schedule";
import { setCheckoutData, getUserRole } from "utils";
import { searchServices } from "pages/public/components/network";
import moment from "moment";
import MyPets from "pages/components/MyPets";

export default class HouseVisitRequest extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);

		this.validator = new SimpleReactValidator({
			// language customization
			/*  messages: {
            required: 'Пакажыце сапраўдны кантактны нумар',
            email: 'That is not an email.',
            default: 'koollll '
          }  */
		});
		this.state = {
			primaryPhone: "",
			secondaryPhone: "",
			address: "",
			city: "",
			state: "",
			zipcode: "",
			data: null,
			service: A4P_SERVICE_HOME_VISITS,
			loading: true,
			submitBtnTxt: "Submit",
			serverErrors: false, // for displaying errors from server
			serverErrorMessage: "Sorry something went wrong" // error message frm server
		};
	}

	onSelectChange(sel) {
		let s = sel.map(item => item.value);
		console.log(`Option selected:`, s);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onFormSubmit(e) {
		e.preventDefault();

		if (this.validator.allValid()) {
			this.setState({
				submitBtnTxt: "Please Wait...",
				serverErrors: false,
				serverErrorMessage: ""
			});

			const { primaryPhone, secondaryPhone } = this.state;

			const checkoutData = {
				paymentType: A4P_PAYMENT_TYPES.SERVICES,
				productId: this.state.service,
				vendorId: A4P_VENDOR_ID,
				vendorServiceId: 1, // dummy
				for_ts: null,
				details: {
					price: A4P_SERVICE_FEES.HOUSE_VISIT,
					primaryPhone,
					secondaryPhone,
					address: `${this.state.address}, ${this.state.city}, ${this.state.zipcode}`
				}
			};
			setCheckoutData(checkoutData);
			this.props.history.push("/checkout");
		} else {
			this.validator.showMessages();
			this.forceUpdate(); // [validator] rerender to show messages for the first time
		}
	}

	render() {
		if (getUserRole() !== A4P_ROLE_USER) {
			return (
				<Redirect
					to={{
						pathname: "/login",
						redirectUrl: this.props.location.pathname
					}}
				/>
			);
		}

		const warningBlock = () => (
			<div className="alert alert-danger">
				<strong className="mr-2">Error!</strong>{" "}
				{this.state.serverErrorMessage}
			</div>
		);

		return (
			<div className="whiteHeaderOffset mb-5">
				<div className="container">
					<div className="row w-75 mx-auto">
						<h3 className="w-100 text-center mb-4">
							Buy Vet House Call Package
						</h3>
						<div className="w-100 border-top">
							{this.state.serverErrors && warningBlock()}

							<form
								id="vsignup"
								method="post"
								onSubmit={this.onFormSubmit}
							>
								<div className="row">
									<p className="col-12 mt-4">
										Contact Information:
									</p>
									<FormElement
										name="primaryPhone"
										className="col-md-6"
										placeHolder="Primary Contact Number"
										state={this.state}
										onChange={this.onChange}
										validator={this.validator}
										validation="required"
									/>
									<FormElement
										name="secondaryPhone"
										className="col-md-6"
										placeHolder="Secondary Contact [Optional]"
										state={this.state}
										onChange={this.onChange}
										validator={this.validator}
										validation=""
									/>
								</div>
								<div className="row">
									<p className="col-12 mt-4">Address:</p>
									<FormElement
										name="address"
										className="col-md-12"
										placeHolder="Address"
										state={this.state}
										onChange={this.onChange}
										validator={this.validator}
										validation="required"
									/>
									<FormElement
										name="city"
										className="col-md-4"
										placeHolder="City"
										state={this.state}
										onChange={this.onChange}
										validator={this.validator}
										validation="required"
									/>
									<FormElement
										name="state"
										className="col-md-4"
										placeHolder="State"
										state={this.state}
										onChange={this.onChange}
										validator={this.validator}
										validation="required"
									/>

									<FormElement
										name="zipcode"
										className="col-md-4"
										placeHolder="ZipCode"
										state={this.state}
										onChange={this.onChange}
										validator={this.validator}
										validation="required"
									/>
								</div>

								<div className="row justify-content-center pt-3 my-3">
									<button
										type="submit"
										className="w-40 btn btn-primary btn-block"
										value="Submit"
									>
										{this.state.submitBtnTxt}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
