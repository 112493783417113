import React, { Component } from "react";
import moment from "moment";
import { _total } from ".";

export default class TTVOrder extends Component {
	render() {
		let { details, for_ts: timeSlot, newPrice } = this.props.data;
		return (
			<div>
				<p>Talk-To-Vet Confirmation:</p>
				<div className="summary">
					<ul className="list-unstyled">
						<li>
							Pet Name:
							<strong className="float-right">
								{details.petName}
							</strong>
						</li>
						<li>
							Date:
							<strong className="float-right">
								{moment(timeSlot).format("ddd, DD MMM")}
							</strong>
						</li>
						<li>
							Time:
							<strong className="float-right">
								{moment(timeSlot).format("hh:mm A")}
							</strong>
						</li>
					</ul>
				</div>
				<ul className="checkout clearfix list-unstyled">
					<li>
						Purpose
						<small className="d-block">Talk-To-Vet Service</small>
					</li>
					<_total newPrice={newPrice} price={details.price} />
				</ul>
			</div>
		);
	}
}
