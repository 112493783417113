import React, { Component } from "react";
import moment from "moment";
import { _total } from ".";

export default class HVOrder extends Component {
  render() {
    let { details, for_ts: timeSlot, newPrice } = this.props.data;
    return (
      <div>
        <p>House Visit Confirmation:</p>
        <div className="summary">
          <ul className="list-unstyled">
            <li>
              Package:
              <strong className="float-right">{details.petName}</strong>
            </li>
            <li>
              Duration:
              <strong className="float-right">
                1 Year
              </strong>
            </li>
            <li>
              Primary Phone:
              <strong className="float-right">
                {details.primaryPhone}
              </strong>
            </li>
          </ul>
        </div>
        <ul className="checkout clearfix list-unstyled">
          <li>
            Address
            <small className="d-block">{details.address}</small>
          </li>
          <li>
            Purpose
            <small className="d-block">House Visit Service</small>
          </li>
          <_total newPrice={newPrice} price={details.price} />
        </ul>
      </div>
    );
  }
}
