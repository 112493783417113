import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class becomeActivDoctorsVet extends Component {
  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">
              Become a ActivDoctors Panel Vet
            </h1>
          </div>
        </div>
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-sm-6">
              <h3 className="mt-0">
                Expand Your Practice and
                <br />
                Increase Revenue
              </h3>
              <h5 className="mt-4 text-black-50" style={{ lineHeight: "28px" }}>
                When you sign up as Panel Vet with ActivDoctors you get a ready
                customer base for online consultations/ opinions, based your
                availability
              </h5>
              <Link to="/for-vet/" className="btn btn-primary btn-lg full-rounded mt-4 text-uppercase px-5">
                JOIN US NOW
                </Link>
            </div>
            <div className="col-sm-6 mt-4 mt-md-0">
              <img
                src="/img/panelImg2.jpg"
                className="img-fluid"
                alt="panelImg2"
              />
            </div>
            <div className="clearfix" />
          </div>
          <h2 className="my-5 text-center">
            Grow your practice &amp; <br />
            Reach Larger Customer Base
          </h2>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex justify-content-sm-between mb-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Attend more appointments and increase revenue
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Clients can consult even while on vacation
                </div>
              </div>
              <div className="d-flex justify-content-sm-between my-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Peace of mind - Consult fee is paid in advance by the client
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Allow you to observe pets in their natural environment
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="bg-light py-5 my-4">
          <div className="container text-center my-4">
            <h2 className="mb-5 pb-4">The Way It Works Is Really Simple</h2>
            <div className="row mt-5">
              <div className="col-sm-4">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "275px" }}
                >
                  <div className="cointSteps rounded-circle">1</div>
                  <h5 className="mt-5 mb-3 text-primary">Client Logins</h5>
                  <div className="fs-1">
                    Clients login to our online platform, request an appointment
                    time and make payment upfront
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "275px" }}
                >
                  <div className="cointSteps rounded-circle">2</div>
                  <h5 className="mt-5 mb-3 text-primary">You Get Notified</h5>
                  <div className="fs-1">
                    You receive a notification and attend the consult at the
                    pre-arranged time via the platform
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "275px" }}
                >
                  <div className="cointSteps rounded-circle">3</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Start Using Our Services
                  </h5>
                  <div className="fs-1">
                    Each client gets access to our handy mobile app – allowing
                    you to promote appointment, refills and products
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <div className="container pt-4 pb-5">
          <h2 className="text-center my-5">Don’t Take Our Word For It</h2>
          <div className="row mb-4">
            <div className="col-lg-4">
              <div
                className="text-center text-white bg-primary p-4 bd-redius-1rem"
                style={{ height: "370px" }}
              >
                <img
                  src="/img/VetsTest1-150x150.jpg"
                  alt=""
                  className="rounded-circle img-fluid"
                  width="100px"
                  height="100px"
                />
                <h5 className="mt-2">
                  <strong>Dr. Jim Cooper</strong>
                </h5>
                <p className="mt-4 text-white">
                  “Your Practice Management piece has helped me to expand my
                  practice, I am able to take more appointments now and manage
                  my patient load much better and oh yes, I earn much more now.
                  I would highly recommend this to independent Vets looking to
                  grow the practice with 0 investment.”
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-md-0">
              <div
                className="text-center text-white bg-primary p-4 bd-redius-1rem"
                style={{ height: "370px" }}
              >
                <img
                  src="/img/VetsTest3-150x150.jpg"
                  alt=""
                  className="rounded-circle img-fluid"
                  width="100px"
                  height="100px"
                />
                <h5 className="mt-2">
                  <strong>Dr. Elina Max</strong>
                </h5>
                <p className="mt-4 text-white">
                  “ActivDoctors Partner Program is a good add on to my current
                  practice as it helps me to fill my open slots and schedules,
                  it’s really hassle-free and I never have to worry about
                  scheduling and billing and administrative headaches as they
                  take care of all that so that I can focus on my practice.”
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-md-0">
              <div
                className="text-center text-white bg-primary p-4 bd-redius-1rem"
                style={{ height: "370px" }}
              >
                <img
                  src="/img/VetsTest2-150x150.jpg"
                  alt=""
                  className="rounded-circle img-fluid"
                  width="100px"
                  height="100px"
                />
                <h5 className="mt-2">
                  <strong>Dr. Dean Landis</strong>
                </h5>
                <p className="mt-4 text-white">
                  “I have had a wonderful experience using the Vet Practice
                  Management Software it has helped me to increase my revenue by
                  over 30% and also helped me streamline my operations. I book
                  more appointments now, see more patients virtually and never
                  have to worry about no show.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
