import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { A4P_VET_DOC_APPOINTMENT_SLOT_DURATION } from "data/config/variables";

class TimeSlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      selectedTimeSlot: null, // used when the mode is 'pick'
      selectedTimeSlots: this.props.selectedTimeSlots
    };
    this.tsClicked = this.tsClicked.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ selectedTimeSlots: props.selectedTimeSlots });
  }

  tsClicked(e) {
    let id = parseInt(e.target.id);
    if (this.props.mode === "pick") {
      this.setState({ selectedTimeSlot: id });
      this.props.onTimeSlotSelected([id]);
    } else {
      let selectedTimeSlots = this.state.selectedTimeSlots;
      let idx = selectedTimeSlots.indexOf(id);
      if (idx === -1) selectedTimeSlots.push(id);
      else selectedTimeSlots.splice(idx, 1);

      this.setState({ selectedTimeSlots });
      this.props.onTimeSlotSelected(selectedTimeSlots);
    }
  }

  render() {
    // populate timeslots to be displayed
    let currAry = [];
    // for selecting a timeslot
    if (this.props.mode === "pick") {
      // show slots from next 3 hrs , so that user doesnt book something immediately
      const ref = moment().add("3", "h");
      currAry = this.props.selectedTimeSlots.filter(
        (item, i) =>
          moment(item).isSame(this.props.currentDt, "day") &&
          ref.isBefore(moment(item))
      );
    }
    // for setting timeslots
    else {
      let dt = moment(this.props.currentDt.startOf("day"));
      while (dt.isSame(this.props.currentDt, "day"))
        currAry.push(
          moment(dt.add(A4P_VET_DOC_APPOINTMENT_SLOT_DURATION, "m")).valueOf()
        );
    }
    currAry.sort();

    const checkIfTrue = item => {
      if (this.state.mode === "pick") {
        return this.state.selectedTimeSlot === item;
      }
      return this.state.selectedTimeSlots.indexOf(item) !== -1;
    };

    return (
      <div className="sch-time-blk d-flex justify-content-center">
        {currAry.length > 0 ? (
          <ul className="sch-cal-time-ul">
            {currAry.map((item, i) => (
              <li key={item}>
                <input
                  type={this.props.mode === "pick" ? "radio" : "checkbox"}
                  id={item}
                  name="ts"
                  onChange={this.tsClicked}
                  checked={checkIfTrue(item)}
                />
                <label htmlFor={item}>{moment(item).format("hh:mm A")}</label>
              </li>
            ))}
          </ul>
        ) : (
          <div className="appBox my-5 px-5 lead">
            Sorry, no slots available for the day!
          </div>
        )}
      </div>
    );
  }
}

TimeSlots.defaultProps = {
  mode: "schedule", // schedule - set schedule; pick - pick a ts
  currentDt: moment(),
  selectedTimeSlots: []
};

TimeSlots.propTypes = {
  onTimeSlotSelected: PropTypes.func.isRequired,
  currentDt: PropTypes.object,
  selectedTimeSlots: PropTypes.array,
  mode: PropTypes.string
};

export default TimeSlots;
