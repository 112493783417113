import React, { Component } from "react";

export default class eConsultation extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault(); 
    this.props.history.push("/e-consultation/order");
  }

  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">
              eConsult – Connect to a Vet from Anywhere in the World
            </h1>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-sm-6">
              <h5
                className="mt-5 pt-5 text-black-50"
                style={{ lineHeight: "28px" }}
              >
                ActivDoctors allows you to connect with your veterinarian via
                webcam for non-emergency care through the ActivDoctors online
                platform, ensuring quality care no matter where you are
              </h5>
              <a
                href="#!"
                onClick={this.onClick}
                className="btn btn-primary btn-lg full-rounded mt-4 px-5"
              >
                START eConsult
              </a>
            </div>
            <div className="col-sm-6">
              <img
                src="/img/panelImg.jpg"
                className="img-fluid"
                alt="Online veterinarian consultation"
              />
            </div>
            <div className="clearfix" />
          </div>
          <h2 className="my-5 py-3 text-center">
            Easy access to your veterinarian without
            <br /> disrupting daily activities.
          </h2>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex justify-content-sm-between mb-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Quick and easy scheduling
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Integrated messenger feature for ease of communication
                </div>
              </div>
              <div className="d-flex justify-content-sm-between my-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Get advice while travelling with pets
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Immediate access to your veterinarian via webcam
                </div>
              </div>
              <div className="d-flex justify-content-sm-between my-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Peace of mind on a range of questions
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Consult from home without having to take time off work
                </div>
              </div>
              <div className="d-flex justify-content-sm-between my-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Saves you the costs of unnecessary travel
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Helps resolve minor issues before they become major ones
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </main>
    );
  }
}
