import { A4P_ROLE_USER } from "data/config/variables";
import IHMRSteps from "pages/ihm/wizard";
import React from "react";
import { Redirect } from "react-router";
import { getUserRole } from "utils";

export default function IHMRequest() {
	if (getUserRole() !== A4P_ROLE_USER) {
		return (
			<Redirect
				to={{
					pathname: "/login",
					redirectUrl: window.location.pathname,
				}}
			/>
		);
	}
  
	return (
		<div className="whiteHeaderOffset mb-5">
			<div className="container">
				<div className="ihm-wrapper">
					<div className="content">
						<div className="mt-4 px-3">
								<IHMRSteps />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
