import React, { Component } from "react";

export default class vetAppointments extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.props.history.push("/vet-appointments/list");
  }

  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">Schedule an Appointment</h1>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col-sm-6">
              <h3>Get Access to Best Veterinarians</h3>
              <h5 className="mt-4 text-black-50" style={{ lineHeight: "28px" }}>
                When you book your Vet Clinic Appointment through ActivDoctors,
                you can be rest assured that you reaching out the very best
                curated vets out there
              </h5>
              <a
                href="#!"
                className="btn btn-primary rounded-pill btn-lg px-4 mt-4"
                onClick={this.onClick}
              >
                Book Appointment
              </a>
            </div>
            <div className="col-sm-6">
              <img
                src="/img/bookAppointment.jpg"
                className="img-fluid"
                alt="veterinarian online appointment booking"
              />
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <section className="bg-light py-5">
          <div className="container text-center my-5">
            <h2 className="mb-5 pb-4">The Way It Works Is Really Simple</h2>
            <div className="row mt-5">
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">1</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Tell Us the Reason for Appointment
                  </h5>
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">2</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Specify Date &amp; Time
                  </h5>
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">3</div>
                  <h5 className="mt-5 mb-3 text-primary">
                    Provide Contact Information
                  </h5>
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="bg-white px-3 py-4 position-relative"
                  style={{ minHeight: "160px" }}
                >
                  <div className="cointSteps rounded-circle">4</div>
                  <h5 className="mt-5 mb-3 text-primary">You are done!</h5>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
          <div className="text-center mt-5">
            <a
              href="#!"
              className="btn btn-primary btn-lg full-rounded mt-4 text-uppercase px-5"
              onClick={this.onClick}
            >
              Schedule Appointment Now
            </a>
          </div>
        </section>
      </main>
    );
  }
}
