import React, { Component } from "react";
import { _total } from ".";

export default class SMOOrder extends Component {
  render() {
    let { details, for_ts: timeSlot, newPrice } = this.props.data;
    return (
      <div>
        <p>SMO Confirmation:</p>
        <div className="summary">
          <ul className="list-unstyled">
            <li>
              Pet Name:
              <strong className="float-right">{details.petName}</strong>
            </li>
          </ul>
        </div>
        <ul className="checkout clearfix list-unstyled">
          <li>
            Purpose
            <small className="d-block">Second Medical Opinion</small>
          </li>
          <_total newPrice={newPrice} price={details.price} />
        </ul>
      </div>
    );
  }
}
