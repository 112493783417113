
import { gqlSend } from "utils/AxiosUtil";

export const searchServices = ({offset, limit, service}) => {
  const q = {
    query: `query( $service: Int, $offset: Int, $limit: Int )
        {
          vendor_services(where: {type: {_eq: $service}}, offset: $offset, limit: $limit) {
            id, name, type, roster, price, details, description, vendor_id, address, city, 
            state, zipcode, country, categories, specialization, pics, pets, education, avatar, provider_name           
          }
        }`,
    variables: {offset, limit, service}
  };
  return gqlSend(q);
}
