import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getSubDomain } from "utils";
import { APP_SUPPORT_EMAIL, APP_NAME } from "data/config/variables";

class PublicFooter extends Component {
  render() {
    return (
      <footer className="bg-light py-5">
        <div className="container pt-3 pb-3">
          <div className="row">
            <div className="col-sm-3">
              <ul className="list-unstyled">
                <li className="pb-2">
                  <strong>Services </strong>
                </li>

                <li className="pb-2">
                  <Link to="/health-assessment/">Health Assessments</Link>
                </li>

                <li className="pb-2">
                  <Link to="/e-consultation/">eConsultation</Link>
                </li>
                <li className="pb-2">
                  <Link to="/second-medical-opinion/">
                    Second Medical Opinion
                  </Link>
                </li>
                <li className="pb-2">
                  <Link to="/medical-records/">Medical Records</Link>
                </li>
                <li className="pb-2">
                  <Link to="#">Download Mobile App</Link>
                </li>
                <li className="pb-2">
                  <Link to="/pricing/">Pricing</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-4">
              <ul className="list-unstyled">
                <li className="pb-2">
                  <strong>Solutions for Doctors/Clinic</strong>
                </li>
                <li className="pb-2">
                  <Link to="#">
                    Practice Management Solution
                  </Link>
                </li>
                <li className="pb-2">
                  <Link to="#">Become ADO Doctor</Link>
                </li>
                <li className="pb-2">
                  <Link to="#">White Label Soluton</Link>
                </li>
                <li className="pb-2">
                  <Link to="#">Contact Us Now</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <ul className="list-unstyled">
                <li className="pb-2">
                  <strong>About</strong>
                </li>
                <li className="pb-2">
                  <Link to="/about/">Our Company</Link>
                </li>
                <li className="pb-2">
                  <a target="_blank" href="http://www.activdoctorsonline.com/about-us/our-team/">Leadership Team</a>
                </li>
                <li className="pb-2">
                  <Link to="#">Our Partners</Link>
                </li> 
              </ul>
            </div>
            <div className="col-sm-2">
              <ul className="list-unstyled">
                <li className="pb-2">
                  <strong>Resources</strong>
                </li>
                <li className="pb-2">
                  <a target="_blank" href="http://www.activdoctorsonline.com/category/blog/">Blog</a>
                </li>
                <li className="pb-2">
                  <a target="_blank" href="http://www.activdoctorsonline.com/about-us/faq/">FAQs</a>
                </li>
                <li className="pb-2">
                  <a target="_blank" href="http://www.activdoctorsonline.com/contact/">Contact</a>
                </li>
                <li className="pb-2">
                  <Link to="#">News</Link>
                </li>
              </ul>
            </div>
            <div className="clearfix" />
          </div>
          <div className="row my-5">
            <div className="col-sm-12 col-lg-4">
              <label htmlFor="subsEmail">Our Monthly Newsletter</label>
              <form className="form-inline">
                <div className="form-group mr-2 mb-2">
                  <input type="email" className="form-control" id="subsEmail" placeholder="Email" />
                </div>
                <button type="submit" className="btn mb-2 btn-secondary">Submit</button>
              </form>
            </div>

            <div className="col-sm-6 col-lg-3 offset-lg-1">
              <div className="pt-2">
                Call Us :{" "}
                <a href="tel:888-51-ACTIV (512-2848)" className="text-black-50">
                888-51-ACTIV (512-2848)
                </a>
              </div>
              <div className="pt-2">
                Mail Us :{" "}
                <a
                  href="mailto:contact@activdoctors.com"
                  className="text-black-50"
                >
                  contact@activdoctors.com
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 s-link mt-3">
              <a href="https://www.facebook.com/ActivDoctorsOnline" target="_blank">
                <i className="fa fa-facebook" />
              </a>
              <a href="https://twitter.com/ActivDoctors" target="_blank">
                <i className="fa fa-twitter" />
              </a>
              <a href="https://www.instagram.com/activ4pets/" target="_blank">
                <i className="fa fa-instagram" />
              </a>
              <a
                href="http://www.youtube.com/user/ActivDoctors?feature=watch"
                target="_blank"
              >
                <i className="fa fa-youtube" />
              </a>
              <a
                href="http://www.linkedin.com/company/1103467"
                target="_blank"
              >
                <i className="fa fa-linkedin" />
              </a> 
            </div>
            <div className="clearfix" />
          </div>
          <div className="text-center">
            <small>
              <Link to="http://www.activdoctorsonline.com/privacy-policy/">Privacy Policy</Link> |{" "}
              <Link to="http://www.activdoctorsonline.com/terms-conditions/">Terms &amp; Conditions</Link> |
              Copyright 2012 - 2020 Activ Doctors Consult, LLC | All Rights Reserved
            </small>
          </div>
        </div>
      </footer>
    );
  }
}

export default PublicFooter;
