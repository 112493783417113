import React, { Component } from "react";

export default class leadershipTeam extends Component {
    render() {
        return (
            <main>
                <div className="position-relative my-5 pageTitle">
                    <img
                        className="img-fluid"
                        src="/img/vetBanner.jpg"
                        alt="inner page bg"
                    />
                    <div className="position-absolute w-100 text-center text-white page-tagline">
                        <h1 className="w-70 m-auto pt-5 pb-5">
                            Leadership Team
                        </h1>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-3 mb-4 mb-md-0">
                            <img
                                src="/img/KimHeeny.jpg"
                                alt="KimHeeny"
                                className="rounded-circle img-fluid"
                                width="225px"
                            />
                        </div>
                        <div className="col-lg-9">
                            <p>
                                <strong>Kimberly Heeney,</strong> Deputy CEO
                                With over 25 years in the healthcare industry,
                                Kimberly’s experience in human medicine has
                                provided the expertise and tools necessary to
                                bring telemedicine to the animal health space.
                            </p>
                            <p>
                                Her experience spans from many aspects of
                                healthcare, which includes Physician practices,
                                pharmacy, and Clinics. Kimberly’s passion for
                                healthcare, pets and animal welfare work is what
                                drove her to propose that Activ Doctors Online
                                make the leap from the human side solutions to
                                offer the same tools, and solutions, “Baby Book
                                “for the Pets.
                            </p>
                            <p>
                                Kimberly oversees the ActivDoctors operations
                                team, and with proven leadership, she ensures
                                that the company’s objectives and that of our
                                partners are followed through to successful
                                outcomes.Kimberly is formally trained as Nurse,
                                Forensic Psychology, and Legal Studies. She is
                                an active member of NAWBO (National Association
                                of Women Business Owners) and collaborates with
                                a number of organizations to support women,
                                children and pets issues, which she is very
                                passionate about.
                            </p>
                            <p>
                                In her spare time you will find Kimberly
                                prepared with food and water in her car scouting
                                out the streets of her hometown looking for
                                strays, to make sure they are safe and provides
                                them with some nutrition. Her saying in life is
                                “one person can make a difference.”
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pb-4">
                        <div className="col-lg-3 mb-4 mb-md-0">
                            <img
                                src="/img/ClaytonJones.jpg"
                                alt="ClaytonJones"
                                className="rounded-circle img-fluid"
                                width="225px"
                            />
                        </div>
                        <div className="col-lg-9">
                            <p>
                                <strong>Clay Jones</strong> Chief Veterinarian
                                officer, DVM
                            </p>
                            <p>
                                Dr. Clay Jones joined ActivDoctors in January of
                                2014. He is passionate about veterinary medicine
                                and greatly enjoys helping people and their
                                pets.
                            </p>
                            <p>
                                Dr. Jones earned his undergraduate degree in
                                Microbiology and his Doctor of Veterinary
                                Medicine from the University of Florida.
                                Following graduation,he returned to his hometown
                                of Tampa and opened the Jones Animal Hospital
                                where he served as medical director and staff
                                veterinarian for twenty-five years.
                            </p>
                            <p>
                                Dr. Jones is the father of two teenagers and is
                                an active member of the Palma Ceia United
                                Methodist Church. He also is a member of the
                                American Veterinary Medical Society and
                                President of the US-Cuba Veterinary Cooperation
                                Society.
                            </p>
                            <p>
                                As our Veterinary Medical Director, Dr. Jones is
                                eager to serve the online ActivDoctors community!
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pb-4">
                        <div className="col-lg-3 mb-4 mb-md-0">
                            <img
                                src="/img/drkavin2.jpg"
                                alt="drkavin2"
                                className="rounded-circle img-fluid"
                                width="225px"
                            />
                        </div>
                        <div className="col-lg-9">
                            <p>
                                <strong>Keven Kimber </strong> Veterinarian
                                Medical Director, DVM
                            </p>
                            <p>
                                Is part of our Veterinary Board Relief Team. Dr.
                                Kimber has a Masters in Zoology from Cornell
                                University, and he graduated from Cornell
                                University College of Veterinary Medicine in
                                2001. He possesses over 15 years of relief
                                experience. He currently maintains Veterinary
                                licensure in the State of New York and
                                Minnesota, and has licensure pending in Florida.
                                Dr. Kimber practices Small Animal and Exotic
                                Medicine with interests in dermatology, internal
                                medicine, preventive medicine, infectious
                                diseases and epidemiology, behavior, and
                                ophthalmology.
                            </p>
                            <p>
                                He has visitation with his two dogs, Hank and
                                Hattie, and resides with a black lab named
                                Neeko, a golden retriever named Jarvis, and a
                                cat named Gina. He enjoys spending time bird
                                watching, fishing, canoeing, kayaking or
                                sailing, and playing and coaching ice hockey and
                                cross country skiing with his family.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pb-4">
                        <div className="col-lg-3 mb-4 mb-md-0">
                            <img
                                src="/img/Kirk.jpeg"
                                alt="Kirk"
                                className="rounded-circle img-fluid"
                                width="225px"
                            />
                        </div>
                        <div className="col-lg-9">
                            <p>
                                <strong>Dr. Kirk DVM</strong>{" "}
                            </p>
                            <p>
                                Dr. Kirk has wanted to be a veterinarian as long
                                as she could remember. With her father being a
                                Veterinarian also, she grew up with a number of
                                pets. She was born and raised in Alabama. Dr.
                                Kirk attended Tuskegee University College of
                                Veterinary Medicine in Tuskegee Alabama. She
                                also completed a clinical residency at Wake
                                Forest School of Medicine in Winston Salem,
                                North Carolina.
                            </p>
                            <p>
                                Her interests include preventative medicine and
                                the challenges of internal medicine. On her off
                                time Dr. Kirk enjoys traveling, outdoor
                                activities, and spending time with family and
                                friends.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pb-4">
                        <div className="col-lg-3 mb-4 mb-md-0">
                            <img
                                src="/img/Sthanu.jpg"
                                alt="Sthanu"
                                className="rounded-circle img-fluid"
                                width="225px"
                            />
                        </div>
                        <div className="col-lg-9">
                            <p>
                                <strong>Dr. Sthanu </strong> DVM
                            </p>
                            <p>
                                Dr. Sthanu grew up in Massachusetts and earned a
                                Bachelors of Science degree in Biology from
                                Northeastern University. She earned her
                                Doctorate of Veterinary Medicine from the
                                Cummings School of Veterinary Medicine at Tufts
                                University in 2007 and moved to Austin, TX soon
                                after. She has spent most of her career
                                providing veterinary relief service to small
                                animal hospitals in the Austin area. Her areas
                                of interest include preventive care and
                                improving the quality of life of senior pets.
                                Dr. Sthanu enjoys the variety of clinic
                                environments and personalities she is able to
                                experience by being a relief veterinarian and
                                strives to provide excellent client and patient
                                care to the hospitals she works with. She is
                                pictured here with her dog, Teddy, who she
                                adopted from a local humane society. She also
                                has three other rescue dogs named Bella, Lucy,
                                and Taystee. On her days off, Dr. Sthanu enjoys
                                spending time with them and with her human
                                family and friends. She also enjoys reading and
                                is an avid Taylor Swift fan.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5 pb-4">
                        <div className="col-lg-3 mb-4 mb-md-0">
                            <img
                                src="/img/Dr-Park2.jpg"
                                alt="Dr-Park2"
                                className="rounded-circle img-fluid"
                                width="225px"
                            />
                        </div>
                        <div className="col-lg-9">
                            <p>
                                <strong>Dr. Bryan Park </strong> DVM
                            </p>
                            <p>
                                Dr. Bryan Park was born and raised in the
                                Chicagoland, IL area. Dr. Park had the care and
                                love for animals at a young age as his father
                                was also a veterinarian. After graduating from
                                St. George's University, school of veterinary
                                medicine, Dr. Park spent years in private
                                veterinarian practices as well as in corporate
                                medicine before joining ActivDoctors in 2019. Dr.
                                Park enjoys all aspects of medicine but has
                                special interests in Small animal preventive
                                medicine, Internal medicine, and Surgery. He is
                                also working on becoming fear free certified.
                                Bryan is a current member of the American
                                Veterinary Medical Association, Illinois State
                                Veterinary Medical Association, and Chicago
                                Veterinary Medical Association.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
