import React, { Component } from "react";
import BookAppointment from "pages/components/BookAppointment";
import { A4P_VENDOR_ID } from "data/config/variables";

export default class AppointmentRequest extends Component {
    render() {
        return (
            <div className="whiteHeaderOffset mb-5">
                <div className="container">
                    <div className="col-md-11 mx-auto">
                        <h3 className="w-100 text-center mb-4">
                            Book an Appointment
                        </h3>
                        <div className="w-100 border-top pt-4">
                            <BookAppointment vendor_id={A4P_VENDOR_ID} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
