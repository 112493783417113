import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class petMedicalRrecords extends Component {
  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">Personal Health Records</h1>
          </div>
        </div>
        <div className="container py-5">
          <div className="row my-5">
            <div className="col-sm-6">
              <h3 className="mt-5">
                You shop online. You bank online.
                <br /> Now you can manage your health online.
              </h3>
              <h5
                className="mt-4 text-black-50"
                style={{ lineHeight: "1.8em" }}
              >
                An online Wellness App is the easy way to keep track of your
                well-being. It gives you all the information you need,
                whenever you need it.
              </h5>
            </div>
            <div className="col-sm-6">
              <img
                src="/img/phr_phone.jpg"
                className="img-fluid"
                alt="phr_phone"
              />
            </div>
            <div className="clearfix" />
          </div>
          <div className="row justify-content-center py-5">
            <div className="col-md-8">
              <div className="d-flex justify-content-sm-between mb-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Sends you an alert when your vaccinations are due
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Records all test results and outcomes from prior vet visits
                </div>
              </div>
              <div className="d-flex justify-content-sm-between my-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  24/7 worldwide access to your health history
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Share information with friends, family, boarders and
                  doctors on the go
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="text-center mt-5">
            <h2 className="mb-5">Want to Know More? Watch This</h2>
            <a href="#!">
              <img className="img-fluid" src="/img/vidMockup1.jpg" alt="ActivDoctors Video" />
            </a>
          </div>
        </div>
        <div className="text-center py-5 bg-secondary">
          <Link
            to="#"
            className="btn btn-outline-secondary border-white rounded-pill btn-lg bottom-btn"
          >
            DOWNLOAD APP NOW <i className="fa fa-arrow-right ml-3" />
          </Link>
        </div>
      </main>
    );
  }
}
