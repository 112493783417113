import React, { Component } from "react";
import PropTypes from "prop-types";
import { getVetDocSpeciality, getVetDocService } from "utils";
import DoctorSchedule from "pages/components/Schedule";

class ClinicListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.data.id,
      showBooking: false
    };
    this.onClick = this.onClick.bind(this);
    this.onTSSelected = this.onTSSelected.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.setState({ showBooking: !this.state.showBooking });
  }

  onTSSelected(ts) { 
    if (this.props.onSelect) this.props.onSelect({ id: this.state.id, ts });
  }

  render() {
    let item = this.props.data;
    return (
      <div className="vet-listing-blk">
        <div className="d-flex">
          <div className="vet-listing-col-1">
            <a href="#!">
              <img
                src={item.avatar || "/img/doc-placeholder.png"}
                className="mr-3 vetListPic img-thumnail"
                alt={item.provider_name} 
              />
            </a>
          </div>
          <div className="vet-listing-col-2">
            <p className="lead text-secondary mb-1">
              <span>{getVetDocSpeciality(item.specialization)}</span>
            </p>
            <p className="vet-listing-title">
              <a href="#!" className="text-decoration-none">
                {item.name}
              </a>
            </p>
            {item.name && <p>{item.name}</p>}
            <p>{item.address + ", " + item.city + ", " + item.zipcode}</p>
            <p className="vet-listing-tags">
              {item.categories.map((c, i) => (
                <span key={i} className="badge">
                  {getVetDocService(c)}
                </span>
              ))}
            </p>
          </div>
          <div className="vet-listing-col-3">
            <p className="vet-listing-fees" title="Consultation Fees">
              <i className="fa fa-money mr-2"> </i>${item.price}
            </p>
            <a href="#!" onClick={this.onClick} className="btn btn-primary">
              Book Appointment
            </a>
          </div>
        </div>
        {this.state.showBooking && (
          <div className="vet-list-booking">
            <p className="mt-4 py-3 border-top text-center">
              Select a time slot to book an appointment
            </p>
            <div className="vet-list-slots">
              <DoctorSchedule
                selectedTimeSlots={item.roster}
                onTimeSlotSelected={this.onTSSelected}
                showCopyToWeeks={false}
                mode="pick"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ClinicListItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.object
};

export default ClinicListItem;
