import React, { Component } from 'react';

export default class downloadApp extends Component {
    render() {
        return (
          <main>
          <div className="position-relative mt-5 pageTitle">
            <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
            <div className="position-absolute w-100 text-center text-white page-tagline">
              <h1 className="w-70 my-auto pt-5 pb-5">Download App</h1></div></div><div className="container py-5">
            <div className="row my-5">
              <div className="col-md-6">
                <h3>
                  Manage your pets care and health with <br />our easy to use app
                </h3>
                <div className="my-4">
                  <div className="p-3 border-left border-primary listBx fs-1">
                    Track Weight And Wellness
                  </div>
                  <div className="p-3 border-left border-primary listBx fs-1 my-3">
                    Set Reminders, Lots Of Them!
                  </div>
                  <div className="p-3 border-left border-primary listBx fs-1">
                    Store Your Favorite Pet Pictures
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-6">
                    <a href="#!" target="_blank" rel="noopener noreferrer"><img src="/img/appStoreBTN.png" alt="ActivDoctors App Store" width="255px" /></a>
                  </div>
                  <div className="col-6">
                    <a href="#!" target="_blank" rel="noopener noreferrer"><img src="/img/playStoreBTN.png" alt="ActivDoctors Play Store" width="255px" /></a>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="col-md-6">
                <img src="/img/a4pAppUI2.jpg" alt="a4pAppUI2" width="540px" />
              </div>
            </div>
            <div className="text-center py-5">
              <h2 className="mb-5">
                Want to Know More? Watch This
              </h2>
              <a href="#!">
                <img className="img-fluid" src="/img/vidMockup1.jpg" alt="ActivDoctors Video" />
              </a>
            </div>
            <h3 className="text-center my-5">Pet Parents Love This Service</h3>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="text-center text-white bg-primary p-4 bd-redius-1rem" style={{height: '370px'}}>
                  <img src="/img/PetsTest1.jpg" alt="PetsTest1" className="rounded-circle img-fluid" width="100px" height="100px" />
                  <h5 className="mt-2"><strong>Fiona</strong></h5>
                  <p className="mt-3 text-white">“You guys at ActivDoctors are truly remarkable, you helped us to get out of our worst nightmare when Jackie was sick, Vet House Call did it for us and we are truly thankful to the team there and the wellness app is so handy, thank you once more”</p>
                </div>
              </div>
              <div className="col-lg-4 mt-5 mt-md-0">
                <div className="text-center text-white bg-primary p-4 bd-redius-1rem" style={{height: '370px'}}>
                  <img src="/img/PetsTest2.jpg" alt="PetsTest2" className="rounded-circle img-fluid" width="100px" height="100px" />
                  <h5 className="mt-2"><strong>Mike</strong></h5>
                  <p className="mt-3 text-white">“Can not thank the team enough for the excellent care they gave my dachshund puppy after he had fallen sick, both the in-home veterinary care and the handy app helped Bruno to recover real quick and now we are so used to managing our pets wellness all through the app.. Thank you”</p>
                </div>
              </div>
              <div className="col-lg-4 mt-5 mt-md-0">
                <div className="text-center text-white bg-primary p-4 bd-redius-1rem" style={{height: '370px'}}>
                  <img src="/img/PetsTest3.jpg" alt="" className="rounded-circle img-fluid" width="100px" height="100px" />
                  <h5 className="mt-2"><strong>Clara</strong></h5>
                  <p className="mt-3 text-white">“Your eConsulation is really awesome, I am a single working mother and this comes so handy whenever Max needs to initial diagnosis by a Vet, I am able to get this done without stepping out or disrupting my work. It has worked really well so far for us.”</p>
                </div>
              </div>
            </div>
          </div>
        </main>
        
          );
    }
}
