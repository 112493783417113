import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class forVet extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.props.history.push("/vendor/login");
  }

  render() {
    return (
      <main>
        <div style={{marginTop: '4em'}}> 
          {/*Services*/}
          <section className="bg-light py-5">
            <div className="container text-center py-2">
              <h2> Our Solutions for Veterinarians </h2>
              <div className="row mt-5">
                <div className="col-sm-4 mb-5 mb-md-0">
                  
                  <Link
                    to="/vet-practice-management-solution"
                    className="bg-primary px-3 py-4 position-relative text-white d-block"
                    style={{
                      minHeight: 275,
                      textDecoration: "none",
                      borderRadius: 10
                    }}
                  >
                    
                    <img src="/img/icons8-new-file-80.png" alt="" />
                    <h5 className="mt-4 mb-3">
                      
                      Practice Management Solution
                    </h5>
                    <div className="fs-1">
                      
                      Improve connectivity, enhance your practice’s reputation,
                      book more appointments. ActivDoctors PMS, a new
                      revolutionary way to manage your practice remotely.
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4 mb-5 mb-md-0">
                  
                  <Link
                    to="/become-ActivDoctors-vet"
                    className="bg-primary px-3 py-4 position-relative text-white d-block"
                    style={{
                      minHeight: 275,
                      textDecoration: "none",
                      borderRadius: 10
                    }}
                  >
                    
                    <img src="/img/vetIcon.png" alt="Become A4P Vet" />
                    <h5 className="mt-4 mb-3"> Become A4P Vet </h5>
                    <div className="fs-1">
                      
                      When you sign up as Panel Vet with ActivDoctors you get a
                      ready customer base for online consultations/opinions,
                      based your availability
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4">
                  
                  <Link
                    to="/partner-relief-vet"
                    className="bg-primary px-3 py-4 position-relative text-white d-block"
                    style={{
                      minHeight: 275,
                      textDecoration: "none",
                      borderRadius: 10
                    }}
                  >
                    
                    <img
                      src="/img/icons8-planner-80.png"
                      alt="Partner Relief Vet"
                    />
                    <h5 className="mt-4 mb-3"> Partner Relief Vet </h5>
                    <div className="fs-1">
                      
                      Our team would work with you to fill in your open slots/
                      schedules for additional work in your local area there by
                      creating an additional stream of revenue.
                    </div>
                  </Link>
                </div>
                <div className="clearfix" />
                <div className="text-center mt-3 mx-auto">
              <a
                href="#!"
                onClick={this.onClick}
                className="btn btn-warning btn-lg full-rounded mt-4 px-5"
              >
                Get Started
              </a>
            </div>
              </div>
            </div>
          </section>
          {/*mission*/}
          <div className="container py-5">
            <div className="row py-2">
              <div className="col-sm-4">
                <div className="leftBx">
                  
                  <strong>
                    We help Vets to Expand their Practice and Increase Revenue
                  </strong>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="row h-sertab">
                  <div className="col-sm-4 mb-5 mb-md-0">
                    <div
                      className="text-center d-block text-dark bg-light px-3 py-4"
                      style={{
                        minHeight: 320,
                        textDecoration: "none !important"
                      }}
                    >
                      
                      <img
                        src="/img/forvetIcon1.png"
                        alt="mobile-app"
                        className="w-50"
                      />
                      <h5 className="mt-3">
                        Move your practice to the cloud and increase patient
                        engagement
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-5 mb-md-0">
                    <div
                      className="text-center d-block text-dark bg-light px-3 py-4"
                      style={{
                        minHeight: 320,
                        textDecoration: "none !important"
                      }}
                    >
                      
                      <img
                        src="/img/forvetIcon2.png"
                        alt="mobile-app"
                        className="w-50"
                      />
                      <h5 className="mt-3">
                        Scale Your Practice, Reach Larger Customer Base
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="text-center d-block text-dark bg-light px-3 py-4"
                      style={{
                        minHeight: 320,
                        textDecoration: "none !important"
                      }}
                    >
                      
                      <img
                        src="/img/forvetIcon3.png"
                        alt="mobile-app"
                        className="w-50"
                      />
                      <h5 className="mt-3">
                        Increase case load, reduce operating costs and monetize
                        your valuable time
                      </h5>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
          {/*How it work*/}
          <section className="bg-light py-5">
            <div className="container text-center my-4">
              <h2 className="mb-5 pb-4"> The Way It Works Is Really Simple </h2>
              <div className="row mt-5">
                <div className="col-sm-4  mb-5 mb-md-0">
                  <div
                    className="bg-white px-3 py-4 position-relative"
                    style={{ minHeight: 275 }}
                  >
                    <div className="cointSteps rounded-circle">1</div>
                    <h5 className="mt-5 mb-3 text-primary"> Client Logins </h5>
                    <div className="fs-1">
                      
                      Clients login to our online platform, request an
                      appointment time and make payment upfront.
                    </div>
                  </div>
                </div>
                <div className="col-sm-4  mb-5 mb-md-0">
                  <div
                    className="bg-white px-3 py-4 position-relative"
                    style={{ minHeight: 275 }}
                  >
                    <div className="cointSteps rounded-circle">2</div>
                    <h5 className="mt-5 mb-3 text-primary">
                      
                      You Get Notified
                    </h5>
                    <div className="fs-1">
                      
                      You receive a notification and attend the consult at the
                      pre-arranged time via the platform.
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className="bg-white px-3 py-4 position-relative"
                    style={{ minHeight: 275 }}
                  >
                    <div className="cointSteps rounded-circle">3</div>
                    <h5 className="mt-5 mb-3 text-primary">
                      
                      Start Using Our Services
                    </h5>
                    <div className="fs-1">
                      
                      Client gets access to our handy mobile app – allowing you
                      to promote appointment, refills and products through push
                      notifications.
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </section>
          <div className="container py-5">
            <div className="text-center py-2">
              <h2 className="mb-5"> Want to Know More? Watch This </h2>
              <a href="#!" className="vp-a">
                
                <img
                  src="/img/vidMockup1.jpg"
                  alt="Online Vets"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
          <div className="container pt-4 pb-5">
            <h2 className="text-center my-5">Don’t Take Our Word For It</h2>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div
                  className="text-center text-white bg-primary p-4 bd-redius-1rem"
                  style={{ height: 370 }}
                >
                  
                  <img
                    src="/img/VetsTest1-150x150.jpg"
                    alt=""
                    className="rounded-circle img-fluid"
                    width="100px"
                    height="100px"
                  />
                  <h5 className="mt-2">
                    <strong>Dr. Jim Cooper</strong>
                  </h5>
                  <p className="mt-4 text-white">
                    “Your Practice Management piece has helped me to expand my
                    practice, I am able to take more appointments now and manage
                    my patient load much better and oh yes, I earn much more
                    now. I would highly recommend this to independent Vets
                    looking to grow the practice with 0 investment.”
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mt-5 mt-md-0">
                <div
                  className="text-center text-white bg-primary p-4 bd-redius-1rem"
                  style={{ height: 370 }}
                >
                  
                  <img
                    src="/img/VetsTest3-150x150.jpg"
                    alt=""
                    className="rounded-circle img-fluid"
                    width="100px"
                    height="100px"
                  />
                  <h5 className="mt-2">
                    <strong>Dr. Elina Max</strong>
                  </h5>
                  <p className="mt-4 text-white">
                    “ActivDoctors Partner Program is a good add on to my current
                    practice as it helps me to fill my open slots and schedules,
                    it’s really hassle-free and I never have to worry about
                    scheduling and billing and administrative headaches as they
                    take care of all that so that I can focus on my practice.”
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mt-5 mt-md-0">
                <div
                  className="text-center text-white bg-primary p-4 bd-redius-1rem"
                  style={{ height: 370 }}
                >
                  
                  <img
                    src="/img/VetsTest2-150x150.jpg"
                    alt=""
                    className="rounded-circle img-fluid"
                    width="100px"
                    height="100px"
                  />
                  <h5 className="mt-2">
                    <strong>Dr. Dean Landis</strong>
                  </h5>
                  <p className="mt-4 text-white">
                    “I have had a wonderful experience using the Vet Practice
                    Management Software it has helped me to increase my revenue
                    by over 30% and also helped me streamline my operations. I
                    book more appointments now, see more patients virtually and
                    never have to worry about no show.”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
