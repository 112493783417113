import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import ListItem from "./ListItem";
import { searchServices } from "../../../components/network";
import { A4P_SERVICE_CLINIC_APPOINTMENTS, A4P_PAYMENT_TYPES } from "data/config/variables";
import { setCheckoutData } from "utils";
import moment from "moment";

class vetList extends Component {
  constructor() {
    super();
    this.state = {
      offset: 0,
      limit: 5,
      items: [],
      endOfList: false,
      error: false
    };
    this.onSelect = this.onSelect.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
  }

  onSelect(data) {
    let it = this.state.items.find(item => item.id === data.id);
    const checkoutData = {
      paymentType : A4P_PAYMENT_TYPES.SERVICES,
      productId: A4P_SERVICE_CLINIC_APPOINTMENTS,
      vendorId: it.vendor_id,
      vendorServiceId: it.id,
      for_ts: moment(data.ts[0]).toISOString(),
      details: {
        clinicName: it.name,
        docName: it.provider_name,
        speciality: it.categories,
        pic: it.avatar,
        price: it.price,
        address: `${it.address}, ${it.city}, ${it.zipcode}`
      }
    };
    setCheckoutData(checkoutData);
    this.props.history.push("/checkout");
  }

  loadMoreItems() {
    if (!this.state.endOfList) {
      searchServices({
        service: A4P_SERVICE_CLINIC_APPOINTMENTS,
        offset: this.state.offset,
        limit: this.state.limit
      })
        .then(r => {
          if (r.data.vendor_services.length)
            this.setState({
              items: this.state.items.concat(r.data.vendor_services),
              offset: this.state.offset + this.state.limit
            });
          else this.setState({ endOfList: true });
        })
        .catch(err => {
          this.setState({ error: true });
          console.log(err);
        });
    }
  }

  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="search results"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <div className="container py-5 py-md-0 ">
              <h4 className="text-center text-white mb-4 mt-2">
                Find trusted medical advice from top vets
              </h4>
              <div className="row">
                <div
                  className="input-group col-xl-8 m-auto"
                  style={{ height: "40px" }}
                >
                  <input
                    type="text"
                    aria-label="First name"
                    className="form-control border-bottom-0 h-100"
                    placeholder="Doc/Clinic Name, Specialization ...."
                  />
                  <input
                    type="text"
                    aria-label="Last name"
                    className="form-control border-bottom-0 h-100"
                    placeholder="Zipcode"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary px-4"
                      type="button"
                      id="button-addon2"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.error ? (
          <div className="container text-center py-5">
            Sorry something went wrong. Please try again later
          </div>
        ) : (
          <div ref="iScroll" className="container py-5">
            <h5>Showing results</h5>
            <div className="bg-white mb-3" ref="iScroll">
              <div>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadMoreItems}
                  hasMore={!this.state.endOfList}
                  loader={
                    <p key={0} className="text-center lead">
                      loading...
                    </p>
                  }
                >
                  {this.state.items.map((item, i) => (
                    <ListItem key={i} data={item} onSelect={this.onSelect} />
                  ))}
                </InfiniteScroll>
                {this.state.endOfList && (
                  <p className="mt-3 text-center lead">That's all folks!</p>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
    );
  }
}

export default vetList;
