import React, { Component } from 'react'

export default class contactUs extends Component {
    render() {
        return (
            <main> 
  <div className="position-relative my-5 pageTitle">
    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
    <div className="position-absolute w-100 text-center text-white page-tagline">
      <h1 className="w-70 m-auto pt-5 pb-5">Shelter Program</h1></div></div><div className="container py-5">	
    <div className="row">
      <div className="col-md-4 mb-5">
        <div className>
          <form>
            <div className="form-group">
              <label htmlFor="name">Your Name (required)</label>
              <input type="name" className="form-control" id="name" aria-describedby="name" placeholder />
            </div>
            <div className="form-group">
              <label htmlFor="companyName">Company (required)</label>
              <input type="name" className="form-control" id="companyName" placeholder />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Your Email (required)</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder />
            </div>
            <div className="form-group">
              <label htmlFor="number">Number</label>
              <input type="tel" className="form-control" id="number" placeholder aria-invalid="false" autoComplete="off" />
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Comments</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows={10} defaultValue={""} />
            </div>
            <button type="submit" className="btn btn-primary">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>

        )
    }
}
