import {  apiSend } from "utils/AxiosUtil"; 
import { getUserId } from "utils";

export const pay = data => {
	return apiSend(data, "/api/payment");
};

export const checkForDiscount = (productId, price) => {
	return apiSend(
		{
			productId,
			price,
			userId: getUserId()
		},
		"/api/payment/check_discount"
	);
};
