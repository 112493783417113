import React, { Component } from "react";
import { Redirect } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import FormElement from "pages/components/form/FormElement";
import {
	A4P_VENDOR_ID,
	A4P_SERVICE_SMO,
	profile_pic_types,
	A4P_SERVICE_FEES,
	A4P_ROLE_USER,
	A4P_PAYMENT_TYPES,
  A4P_SERVICE_CHAT,
} from "data/config/variables";
import { setCheckoutData, getUserRole } from "utils";
import MyDropZone from "pages/components/form/MyDropZone";
import MyPets from "pages/components/MyPets";

export default class ChatRequest extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onPetSelected = this.onPetSelected.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);

		this.validator = new SimpleReactValidator({
			// language customization
			/*  messages: {
            required: 'Пакажыце сапраўдны кантактны нумар',
            email: 'That is not an email.',
            default: 'koollll '
          }  */
		});
		this.state = {
			pet: null,
			files: null,
			question: null,
			authorize: null,
			query: null,
			service: A4P_SERVICE_CHAT,
			loading: false,
			submitBtnTxt: "Submit",
			serverErrors: false, // for displaying errors from server
			serverErrorMessage: "Sorry something went wrong", // error message frm server
		};
	}

	onSelectChange(sel) {
		let s = sel.map((item) => item.value);
		console.log(`Option selected:`, s);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onPetSelected(pet) {
		this.setState({ pet });
	}

	onFormSubmit(e) {
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({
				submitBtnTxt: "Please Wait...",
				serverErrors: false,
				serverErrorMessage: "",
			});

			const checkoutData = {
				paymentType: A4P_PAYMENT_TYPES.SERVICES,
				productId: this.state.service,
				vendorId: A4P_VENDOR_ID,
				vendorServiceId: 1, // dummy
				for_ts: null,
				details: {
					price: A4P_SERVICE_FEES.CHAT_WITH_VET,
					petId: this.state.pet.id,
					petName: this.state.pet.name,
					petType: this.state.pet.type,
					petBreed: this.state.pet.breed,
					phrId: this.state.pet.phr_id,
					question: this.state.question,
					//query: this.state.query,
					//files: this.state.files,
				},
			};
			setCheckoutData(checkoutData);
			this.props.history.push("/checkout");
		} else {
			this.validator.showMessages();
			this.forceUpdate(); // [validator] rerender to show messages for the first time
		}
	}

	render() {
		if (getUserRole() !== A4P_ROLE_USER) {
			return (
				<Redirect
					to={{
						pathname: "/login",
						redirectUrl: this.props.location.pathname,
					}}
				/>
			);
		}

		const warningBlock = () => (
			<div className="alert alert-danger">
				<strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
			</div>
		);

		return (
			<div className="whiteHeaderOffset mb-5">
				<div className="container">
					<div className="row w-75 mx-auto">
						<h3 className="w-100 text-center">ASK A QUESTION FOR FREE</h3>
						<p className="text-muted text-center w-100">
							The veterinarian may take upto 36 hours to respond
						</p>
						<div className="w-100 border-top">
							{this.state.serverErrors && warningBlock()}
							{this.state.loading ? (
								<p className="mt-5 text-center">loading....</p>
							) : (
								<form id="vsignup" method="post" onSubmit={this.onFormSubmit}>
									<div className="row">
										<p className="col-12 mt-4">Select A Pet:</p>
										<div className="col-12 mt-2">
											<MyPets onPetSelected={this.onPetSelected} />
										</div>
										{this.validator.message("Pet", this.state.pet, "required")}
									</div>
									<div className="row">
										<p className="col-12 mt-4">
											Ask any question below. Out vet experts are standing by:
										</p>
										<FormElement
											name="question"
											className="col-md-12"
											state={this.state}
											validator={this.validator}
											validation="required"
										>
											<textarea
												name="question"
												rows="3"
												className="form-control"
												placeholder="Type your question here (500 characters max)"
												onChange={this.onChange}
											/>
										</FormElement>
									</div>

									<div className="row justify-content-center my-3">
										<button
											type="submit"
											className="w-40 btn btn-primary btn-block"
											value="Submit"
										>
											{this.state.submitBtnTxt}
										</button>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
