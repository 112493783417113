import React, { Component } from "react";
import moment from "moment";
import { _total } from ".";

export default class VAOrder extends Component {
  render() {
    let { details, for_ts: timeSlot, newPrice } = this.props.data;
    return (
      <div>
        <p>Vet Appointment Confirmation:</p>
        <div className="summary">
          <ul className="list-unstyled">
            <li>
              Name:
              <strong className="float-right">{details.clinicName}</strong>
            </li>
            <li>
              Date:
              <strong className="float-right">
                {moment(timeSlot).format("ddd, DD MMM")}
              </strong>
            </li>
            <li>
              Time:
              <strong className="float-right">
                {moment(timeSlot).format("hh:mm A")}
              </strong>
            </li>
          </ul>
        </div>
        <ul className="checkout clearfix list-unstyled">
          <li>
            Address
            <small className="d-block">{details.address}</small>
          </li>
          <li>
            Purpose
            <small className="d-block">General Consultation</small>
          </li>
          <_total newPrice={newPrice} price={details.price} />
        </ul>
      </div>
    );
  }
}
