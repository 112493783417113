import React, { Component } from "react";

export default class HealthAssessment extends Component {
	constructor() {
		super();
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		e.preventDefault();
		this.props.history.push("/health-assessment/order");
	}

	render() {
		return (
			<main>
				<div className="position-relative mt-5 pageTitle">
					<img
						className="img-fluid"
						src="/img/vetBanner.jpg" 
						alt="inner page bg"
					/>
					<div className="position-absolute w-100 text-center text-white page-tagline">
						<h1 className="w-70 m-auto pt-5 pb-5">Health Screening Tool</h1>
					</div>
				</div>
				<div className="container py-5">
					<h3 className="text-center">It’s Really Simple</h3>
					<div className="row my-4">
						<div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
							<div className="p-3 border-left border-primary listBx fs-1">
								Answer a Few Questions
							</div>
							<div className="p-3 border-left border-primary listBx fs-1 my-3">
								Tool Generates Reports and Prescription
							</div>
							<div className="p-3 border-left border-primary listBx fs-1">
								Consult A Doctor Online
							</div>
							<div className="text-center">
								<a
									href="#!"
									onClick={this.onClick}
									className="btn btn-primary btn-lg full-rounded mt-5 text-uppercase px-5"
								>
									Take Test
								</a>
							</div>
						</div>
						<div className="clearfix" />
						<h5 className="text-center mt-5 mb-3 w-100">
							Dedicated to making health care easy to accessible to all
						</h5>
						<p
							style={{
								width: "75%",
								textAlign: "center",
								margin: "auto",
								lineHeight: "2em",
								fontSize: "1.2em",
								color: "#666",
								paddingTop: "20px",
							}}
						>
							The <strong>Health Screening tool</strong> is a comprehensive
							Health status based on the integration of multi-dimensional
							relevant data to detect and cure infra-clinical diseases or to
							identify and prevent predictable diseases. It auto-generates
							medical reports on current and further health breakdowns leading
							to a smart schedule of healthcare actions. It can come up with
							personalized lifestyle recommendations or safety advice, and the
							best choice of nutrients or medications for the patients. 
						</p>
					</div>
				</div>
			</main>
		);
	}
}
