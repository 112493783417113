import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import {
    APP_CONSULTATION_TYPE_CLINIC,
    APP_CONSULTATION_TYPE_VIDEO,
    APP_CONSULTATION_TYPE_TELEPHONE,
    APP_CONSULTATION_TYPE_CHAT,
    APP_CLOUD_TELE_CONSULT,
} from "data/config/variables";
import { getBookingLink } from ".";

export default class BookAppointmentConfirmation extends Component {

    getAppointmentInstruction = (slot, bookingId, userId) => {
        const slotStr = moment
            .tz(slot.time, slot.timezone)
            .format("DD-MMM-YYYY, hh:mm A z");

        const linkId = getBookingLink({ userId, bookingId });

        switch (slot.service.type) {
            case APP_CONSULTATION_TYPE_CLINIC:
                return (
                    <span>
                        Please visit the clinic on {slotStr} and present your
                        name.
                    </span>
                );
            case APP_CONSULTATION_TYPE_VIDEO:
                return (
                    <span>
                        Please visit this link: <a href={linkId}>{linkId}</a> on{" "}
                        {slotStr} for the video consultation. <br />
                        You can view the link from your smartphone or any device
                        with a webcam.
                    </span>
                );
            case APP_CONSULTATION_TYPE_TELEPHONE:
                return (
                    APP_CLOUD_TELE_CONSULT && (
                        <span>
                            Please visit the link: <a href={linkId}>{linkId}</a>{" "}
                            on {slotStr} for the telephonic consultation. <br />
                            The link will provide a temporary number(only valid
                            for the duration) to call the doctor.
                        </span>
                    )
                );
            case APP_CONSULTATION_TYPE_CHAT:
                return (
                    <span>
                        Please visit the link: <a href={linkId}>{linkId}</a> on{" "}
                        {slotStr} to begin chat consultation.
                    </span>
                );
        }
    };

    render() {
        const { slot, bookingId, info } = this.props;
        return (
            <div>
                <div className="p-3 border">
                    <div className="container py-5 m-auto">
                        <div className="text-center">
                            <div className="icon icon--order-success svg add_bottom_15">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={72}
                                    height={72}
                                >
                                    <g
                                        fill="none"
                                        stroke="#8EC343"
                                        strokeWidth={2}
                                    >
                                        <circle
                                            cx={36}
                                            cy={36}
                                            r={35}
                                            style={{
                                                strokeDasharray: "240px, 240px",
                                                strokeDashoffset: "480px",
                                            }}
                                        />
                                        <path
                                            d="M17.417,37.778l9.93,9.909l25.444-25.393"
                                            style={{
                                                strokeDasharray: "50px, 50px",
                                                strokeDashoffset: "0px",
                                            }}
                                        />
                                    </g>
                                </svg>
                            </div>
                            <h2 className="my-3 pb-2">
                                Your Appointment is confirmed!
                            </h2>
                            <p className="lead text-muted py-2">
                                {this.getAppointmentInstruction(
                                    slot,
                                    bookingId,
                                    info.user.id
                                )}
                            </p>
                            <p className="text-muted py-2">
                                We will send you a confirmation email and sms
                                with the order information and instructions.
                                <br />
                                You can also login into your dashboard to manage
                                your appointments.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
