import React, { Component } from "react";

import SimpleReactValidator from "simple-react-validator";
import FormElement from "pages/components/form/FormElement";

import {
	A4P_SERVICE_CLINIC_APPOINTMENTS,
	A4P_SERVICE_HOME_VISITS,
	A4P_SERVICE_TALKTOAVET,
	A4P_SERVICE_ECONSULTATION,
	A4P_SERVICE_SMO,
	A4P_PAYMENT_TYPES
} from "data/config/variables";
import VAOrder from "./VAOrder";
import ECOrder from "./ECOrder";
import TTVOrder from "./TTVOrder";
import HVOrder from "./HVOrder";
import SMOOrder from "./SMOOrder";
import PromoPlan from "./PromoPlan";

export default class PaymentPage extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.validator = new SimpleReactValidator({});
		this.state = {
			name: "",
			cardNumber: "",
			expDt: "",
			ccv: ""
		};
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onFormSubmit(e) {
		e.preventDefault();
		const { newPrice } = this.props.info;
		const isValidTotal = !(newPrice===0); 
		if (!isValidTotal || this.validator.allValid()) {
			let { cardNumber, name, ccv, expDt } = this.state;
			this.props.onPaymentComplete({ cardNumber, name, ccv, expDt });
		} else {
			this.validator.showMessages();
			this.forceUpdate(); // [validator] rerender to show messages for the first time
		}
	}

	renderComponent = () => {
		let { info: data } = this.props;
		if (data.paymentType === A4P_PAYMENT_TYPES.SERVICES) {
			switch (data.productId) {
				case A4P_SERVICE_CLINIC_APPOINTMENTS:
					return <VAOrder data={data} />;
				case A4P_SERVICE_ECONSULTATION:
					return <ECOrder data={data} />;
				case A4P_SERVICE_TALKTOAVET:
					return <TTVOrder data={data} />;
				case A4P_SERVICE_HOME_VISITS:
					return <HVOrder data={data} />;
				case A4P_SERVICE_SMO:
					return <SMOOrder data={data} />;
			}
		} else if (data.paymentType === A4P_PAYMENT_TYPES.PROMO) {
			return <PromoPlan data={data.details} />;
		}
	};

	render() {
		const { newPrice } = this.props.info;
		const isValidTotal = !(newPrice===0); 
		return (
			<div className="container py-5">
				{this.props.paymentError && (
					<div className="alert alert-danger">
						<strong className="mr-3">Error!</strong>
						{this.props.paymentError}
					</div>
				)}
				<form id="vsignup" method="post" onSubmit={this.onFormSubmit}>
					<div className="row">
						{isValidTotal && (
							<div className="col-md-8">
								<div className="bg-light p-3">
									Review and Complete Payment
								</div>
								<div className="form_title mt-3">
									<h4>
										<strong>1</strong>Payment Information
									</h4>
								</div>
								<div className="step py-4">
									<div className="form-group">
										<label>Name on card</label>
										<FormElement
											className=""
											name="name"
											placeHolder="John Lathum"
											state={this.state}
											onChange={this.onChange}
											validator={this.validator}
											validation="required"
										/>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Card number</label>
												<FormElement
													className=""
													name="cardNumber"
													placeHolder="xxxx - xxxx - xxxx - xxxx"
													state={this.state}
													onChange={this.onChange}
													validator={this.validator}
													validation="required|numeric|min:13|max:16"
												/>
											</div>
										</div>
										<div className="d-none d-md-block col-md-6 pt-4 mt-2">
											<img
												src="/img/payments-methods.png"
												alt="Cards"
												className="cards"
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<label>Expiration date</label>
											<div className="row">
												<div className="col-12">
													<div className="form-group">
														<FormElement
															className=""
															name="expDt"
															type="month"
															placeHolder="YYYY-MM"
															state={this.state}
															onChange={
																this.onChange
															}
															validator={
																this.validator
															}
															validation="required"
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Security code</label>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<FormElement
																className=""
																name="ccv"
																placeHolder="CCV"
																state={
																	this.state
																}
																onChange={
																	this
																		.onChange
																}
																validator={
																	this
																		.validator
																}
																validation="required|numeric|min:3|max:4"
															/>
														</div>
													</div>
													<div className="col-md-6">
														<img
															src="/img/icon_ccv.gif"
															width={50}
															height={29}
															alt="ccv"
														/>
														<small>
															Last 3 digits
														</small>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/*End row */}
									<small className="text-muted">
										Your credit card details are safe with
										us. We never save it on our servers
									</small>
								</div>
							</div>
						)}
						<div
							className={`${
								isValidTotal ? "col-md-4" : "col-12"
							}`}
						>
							<div id="stickySidebar" style={{}}>
								<div
									className="sidebar__inner bg-white shadow p-3"
									style={{ position: "relative" }}
								>
									<div className="form_title mt-2 mb-3">
										<h4>
											<strong>2</strong>Your Order
										</h4>
									</div>
									{this.renderComponent()}
									<div className="text-center">
										<button
											type="submit"
											className="btn btn-primary rounded-pill"
										>
											{this.props.submitBtnTxt}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="clearfix" />
					</div>
				</form>
			</div>
		);
	}
}

export const _total = ({newPrice, price}) => (
	<React.Fragment>
		{newPrice != undefined && (
			<li className="text-danger font-weight-bold">
				Discount{" "}
				<strong className="price">${price - newPrice}</strong>
			</li>
		)}
		<li className="total font-weight-bold">
			Total{" "}
			<strong className="price">
				${newPrice != undefined ? newPrice : price}
			</strong>
		</li>
	</React.Fragment>
);
