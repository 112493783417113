import {
	A4P_ROLE_USER,
	A4P_APPOINTMENT_STATUSES,
	A4P_ROLE_VENDOR,
	A4P_VENDOR_TYPE,
	A4P_SMO_STATUSES,
    A4P_SERVICE_SMO,
} from "data/config/variables";
import { gqlSend } from "utils/AxiosUtil";
import { getUserId, getSubRole, getVendorId, getUserRole } from "utils";

export const searchSMO = (params) => {
	const {
		offset = 0,
		limit = 10,

		status,
		listing,
		sortBy,
		id,

		role = getUserRole(),
		vendorId = getVendorId(),
		userId = getUserId(),
		subRole = getSubRole(),
	} = params;

	let recs = "";
	switch (status) {
		case A4P_SMO_STATUSES.NEW:
			recs = `, status: {_eq: ${A4P_SMO_STATUSES.NEW}}`;
			break;
		case A4P_SMO_STATUSES.ASSIGNED:
			recs = `, status: {_eq: ${A4P_SMO_STATUSES.ASSIGNED}}`;
			break;
		case A4P_SMO_STATUSES.PENDING:
			recs = `, status: {_eq: ${A4P_SMO_STATUSES.PENDING}}`;
			break;
		case A4P_SMO_STATUSES.COMPLETE:
			recs = `, status: {_eq: ${A4P_SMO_STATUSES.COMPLETE}}`;
			break;
		case A4P_SMO_STATUSES.RETURNED:
			recs = `, status: {_eq: ${A4P_SMO_STATUSES.RETURNED}}`;
			break;
		case A4P_SMO_STATUSES.CANCELED:
			recs = `, status: {_eq: ${A4P_SMO_STATUSES.CANCELED}}`;
			break;
		default:
			recs = "";
	}

	let whr = `user_id: {_eq: ${userId}} `;
	if (role === A4P_ROLE_VENDOR) {
		whr = `vendor_id: {_eq: ${vendorId}} 
               ${
									subRole === A4P_VENDOR_TYPE.USER
										? `, vendor_listing: {managers: {_contains: [${userId}]}}`
										: ""
								}`;
	}
	whr = ` ${whr}  
            , service_id: {_eq: ${A4P_SERVICE_SMO}} 
            ${recs}  
            ${listing ? `, vendor_listing_id: {_eq: ${listing}}` : ""} 
            ${id ? `, id: {_eq: ${id}}` : ""} `;

	const srt = `${sortBy}: ${sortBy === "slot" ? `asc` : `desc`}`;

	const q = {
		query: `{
            appointments(where: {${whr}}, limit: ${limit}, offset: ${offset}, order_by: {${srt}}) {
                id, status, created_at, updated_at, details
        }
      }`,
	};

	return gqlSend(q);
};

export const cancelAppointment = (id) => {
	return gqlSend({
		query: ` 
            mutation {
                update_bookings(where: {id: {_eq: ${id}}}, _set: {status: ${A4P_SMO_STATUSES.CANCELED}}) {
                    affected_rows
                    }
            }`,
	});
};
