import React, { Component } from "react";

export default class steps extends Component {
  render() {
    const idx = this.props.currentStep;
    return (
      <div>
        <ol className="progtrckr">
          {this.props.steps.map((item, i) => (
            <li key={i} className={i > idx ? 'progtrckr-todo': ''}>
              <i className={`fa ${i === idx? 'fa-spinner': 'fa-check'}`}></i> {/* i is hidden for todo state*/}
              <span>{item}</span>
            </li>
          ))}

        </ol>
      </div>
    );
  }
}

// todo add proptypes