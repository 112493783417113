import React, { Component } from "react";

export default class partnerReliefVet extends Component {
  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">Partner Relief Vet</h1>
          </div>
        </div>
        <div className="container py-5">
          <div className="row my-5">
            <div className="col-sm-6">
              <h3>Book Your Open Slots &amp; Increase Revenue</h3>
              <h5 className="mt-4 text-black-50" style={{ lineHeight: "28px" }}>
                You could work with ActivDoctors in an offline model. This would
                mean in person, additional work in your area that would augment
                your current work. ActivDoctors team would work with you to fill
                in your open slots/ schedules for additional work there by
                creating an additional stream of revenue without any disruption.
              </h5>
            </div>
            <div className="col-sm-6">
              <div className="p-3 border-left border-primary listBx fs-1">
                Attend more appointments and increase revenue
              </div>
              <div className="p-3 border-left border-primary listBx fs-1 my-3">
                No hassles – We handle scheduling &amp; billing
              </div>
              <div className="p-3 border-left border-primary listBx fs-1">
                Great Rewards &amp; Endless Possibilities for growth
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="text-center py-5 bg-secondary">
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-8 offset-md-2">
                <h5
                  className="text-center text-white mb-3"
                  style={{ lineHeight: "30px" }}
                >
                  Activ4vets gives you an opportunity to grow your revenue by up
                  to 35% by having our team work with you to fill in your open
                  slots/ schedules for additional work in your area.
                </h5>
              </div>
              <div className="clearfix" />
            </div>
            <a
              href="/download-app"
              className="btn btn-outline-secondary border-white rounded-pill btn-lg bottom-btn"
            >
              DOWNLOAD APP NOW <i className="fa fa-arrow-right ml-3" />
            </a>
          </div>
        </div>
      </main>
    );
  }
}
