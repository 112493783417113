import React, { Component } from "react";
import MyProfile from "../components/MyProfile";
import SectionHead from "../components/sectionHead";

export default class UserMyQuestions extends Component {
	render() {
		return (
			<div>
				<div className="mt-4 p-3 container">
					<div>
						<SectionHead
							title="My Questions"
							createTxt="Ask Question"
							onCreateLinkClick={true}
							createLink="/talk-to-a-vet/order"
						/>
						<div className="p-3 border">
							<p className="my-2 lead text-muted p-3">No Questions Found</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
